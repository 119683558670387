<template>
  <v-container fluid>
    <!--<course-calendar></course-calendar>-->
    <!--<course-list></course-list>-->
    <router-view></router-view>
  </v-container>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex'

export default {
  name: 'AgendaOrList',
  components: {
    CourseCalendar: () => import ('@/components/courses/CourseIndexAgenda.vue'),
    CourseList: () => import ('@/components/courses/CourseIndexList.vue'),
  },

  data() {
    return {
      key: null
    }
  },
  methods: {
    names() {
      
    },
    ...mapActions({
      // function: 'Module/action'
    })
  },
  computed: {
    name() {
      return null 
    },
    ...mapState({
      // data: state=> state.Module.state_var
    })
  },
  watch: {
    data(nv,ov) {
      if (nv){
      } else if (nv == false){
      }
    }
  },
}
</script>

<style scoped>

</style>